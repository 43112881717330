import { FormattedMessage, useIntl } from "react-intl";
import { MidtownLogo } from "../components/MidtownLogo";
import { StarsImage } from "../components/StarsImage";
import { Separator } from "../components/ui/separator";

export default function SuccessScreen() {

	const intl = useIntl()

	const appPromoLink = intl.formatMessage({ id: "app.success.promo.link.url", defaultMessage: "https://www.midtown.com/member-app/" })

	return (<>
		<MidtownLogo />
		<div className='p-6 flex flex-col items-center'>
			<StarsImage className="mt-10 mr-2" />
			<h1 className="mt-10 font-medium text-2xl"><FormattedMessage id="app.success.header" defaultMessage="Account Update Successful" /></h1>

			<div className="md:w-1/3 flex flex-col items-center">

				<Separator className="mt-6 w-2/3" />

				<p className="mt-6 mx-8 text-sm text-stone-500 text-center">
					<FormattedMessage id="app.success.message" defaultMessage="Your account information has been successfully updated. For any questions or future updates, contact member services by filling out {supportForm} or calling your club's main phone number." values={{
						supportForm: (<a className="underline text-sky-500 text-nowrap" href="https://www.midtown.com/contact-us/"><FormattedMessage id="app.success.message.link.text" defaultMessage="this form" /></a>),
					}} />
				</p>

				<p className="mt-6 mx-8 text-sm text-stone-500 text-center">
					<FormattedMessage id="app.success.promo.message" defaultMessage="Want to see weekly previews of what's to come in your new member app? {appLink}" values={{
						appLink: (<a className="underline text-sky-500 text-nowrap" href={appPromoLink} ><FormattedMessage id="app.success.promo.link.text" defaultMessage="click here" /></a>)
					}} />
				</p>

			</div>
		</div>
	</>)
}