import { Routes, Route, useLocation, Navigate } from 'react-router-dom'
import { IntlProvider } from 'react-intl'
import './App.css'
import LoginScreen from './screens/login/LoginScreen'
import HomeScreen from './screens/home/HomeScreen'
import SuccessScreen from './screens/SuccessScreen'
import ResetPasswordScreen from './screens/reset-password/ResetPasswordScreen'
import { AuthProvider, useAuth } from './lib/authHook'
import french from './lang/fr'
import { Toaster } from './components/ui/toaster'
import AlreadySubmittedScreen from './screens/AlreadySubmittedScreen'

const messages = navigator.language.startsWith('fr') ? french : {}

function App() {
	return (
		<IntlProvider messages={messages} locale={navigator.language} defaultLocale="en-US" defaultRichTextElements={{ b: (chunks) => <b className="font-bold">{chunks}</b>, }}>
			<AuthProvider>
				<div className="flex flex-col items-center">

					<Routes>
						<Route path="/">
							<Route index element={
								<RequireAuth>
									<HomeScreen />
								</RequireAuth>
							} />

							<Route path="success" element={<SuccessScreen />} />

							<Route path="submitted" element={<AlreadySubmittedScreen />} />

							<Route path="login" element={<LoginScreen />} />
							<Route path="password-reset" element={<ResetPasswordScreen />} />
						</Route>
					</Routes>
				</div>
			</AuthProvider>
			<Toaster />
		</IntlProvider>
	)
}

function RequireAuth({ children }: { children: JSX.Element }) {
	let auth = useAuth();
	let location = useLocation();

	if (!auth.user.id) {
		// Redirect them to the /login page, but save the current location they were
		// trying to go to when they were redirected. This allows us to send them
		// along to that page after they login, which is a nicer user experience
		// than dropping them off on the home page.
		return <Navigate to="/login" state={{ from: location }} replace />;
	}

	return children;
}


export default App;
