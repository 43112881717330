import { FormattedMessage } from "react-intl";
import { PrimaryMember } from "../../model/Members";
import { Control, useFormContext } from "react-hook-form";
import { fr, enUS } from 'date-fns/locale';
import { FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from "../../components/ui/form";
import { Input, PasswordInput } from "../../components/ui/input";
import { BirthDateField } from "./BirthDateField";
import { EmailField } from "./EmailField";
import { MemberHeader } from "./MemberHeader";
import { PhoneNumberField } from "./PhoneNumberField";

export interface PrimaryMemberFormProps {
	member: PrimaryMember
	control: Control
	disabled: boolean
}

export function PrimaryMemberForm({ member, control, disabled }: PrimaryMemberFormProps) {

	const locale = navigator.language.startsWith("fr") ? fr : enUS

	const { getFieldState, formState } = useFormContext()
	const { error: passwordError } = getFieldState(`${member.id}.password`, formState)

	return (
		<div className="mt-10">
			<MemberHeader name={member.name} type={member.type} />

			<div className="md:flex md:gap-4">
				<BirthDateField className="mt-4 w-full md:w-80" control={control} member={member} locale={locale} disabled={disabled} />

				<EmailField member={member} required={true} control={control} disabled={disabled} />

				<FormField
					disabled={disabled}
					control={control}
					name={`${member.id}.username`}
					render={({ field }) => (
						<FormItem className="mt-4 w-full md:w-80">
							<FormLabel className="mx-2 md:mx-0 text-base text-stone-500 truncate"><FormattedMessage id="app.home.member.username" defaultMessage="*Username (Email Recommended)" /></FormLabel>
							<FormControl>
								<Input required {...field} className="md:border-x-0 md:border-t-0 md:shadow-none md:rounded-none md:focus-visible:rounded-md" />
							</FormControl>
							<FormMessage />
						</FormItem>
					)}
				/>
			</div>

			<PhoneNumberField member={member} required={true} control={control} disabled={disabled} />

			<p className="text-base mt-8 mx-2 md:mx-0"><FormattedMessage id="app.home.member.password.header" defaultMessage="Update Password (Optional)" /></p>

			<div className="md:flex md:gap-4">

				<FormField
					disabled={disabled}
					control={control}
					name={`${member.id}.currentPassword`}
					render={({ field }) => (
						<FormItem className="mt-4 w-full md:w-80">
							<FormLabel className="mx-2 md:mx-0 text-base text-stone-500 truncate"><FormattedMessage id="app.home.member.password.current" defaultMessage="Current Password" /></FormLabel>
							<FormControl>
								<PasswordInput {...field} autoComplete="current-password" className="md:border-x-0 md:border-t-0 md:shadow-none md:rounded-none md:focus-visible:rounded-md" />
							</FormControl>
							<FormMessage />
						</FormItem>
					)}
				/>

				<FormField
					disabled={disabled}
					control={control}
					name={`${member.id}.password`}
					render={({ field }) => (
						<FormItem className="mt-4 w-full md:w-80">
							<FormLabel className="mx-2 md:mx-0 text-base text-stone-500 truncate"><FormattedMessage id="app.home.member.password.new" defaultMessage="New Password" /></FormLabel>
							<FormControl>
								<PasswordInput {...field} autoComplete="new-password" maxLength={12} className="md:border-x-0 md:border-t-0 md:shadow-none md:rounded-none md:focus-visible:rounded-md" />
							</FormControl>
							<FormDescription className={passwordError ? "text-destructive" : ""}>
								<FormattedMessage id="app.home.member.password.requirements" defaultMessage="Password must be between 6-12 characters." />
							</FormDescription>
						</FormItem>
					)}
				/>

				<FormField
					disabled={disabled}
					control={control}
					name={`${member.id}.passwordConfirmation`}
					render={({ field }) => (
						<FormItem className="mt-4 w-full md:w-80">
							<FormLabel className="mx-2 md:mx-0 text-base text-stone-500 truncate"><FormattedMessage id="app.home.member.password.confirmation" defaultMessage="Confirm New Password" /></FormLabel>
							<FormControl>
								<PasswordInput {...field} autoComplete="new-password" maxLength={12} className="md:border-x-0 md:border-t-0 md:shadow-none md:rounded-none md:focus-visible:rounded-md" />
							</FormControl>
							<FormMessage />
						</FormItem>
					)}
				/>
			</div>
		</div>
	)
}