import { SubmitHandler, useForm } from "react-hook-form"

import { Button } from "../../components/ui/button"
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormMessage,
} from "../../components/ui/form"
import { Input } from "../../components/ui/input"
import { useState } from "react"
import { ResetPasswordResponse } from "../../model/ResetPasswordResponse"
import { FormattedMessage, useIntl } from "react-intl"
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { RestApiResponseCode } from "../../model/RestApiResponseCode"
import { ErrorMessageWithSupportLink } from "../../components/ErrorMessageWithSupportLink"
import { SpinnerIcon } from "../../components/SpinnerIcon"

export interface ResetPasswordFormProps {
	onSubmit: (email: string) => Promise<ResetPasswordResponse>
}

export function ResetPasswordForm({ onSubmit }: ResetPasswordFormProps) {
	const intl = useIntl()

	const invalidEmailMessage = intl.formatMessage({ id: "app.passwordreset.error.email.invalid", defaultMessage: "Please enter a valid email address." })

	const formSchema = z.object({
		email: z.string()
			.email({ message: invalidEmailMessage })
			.max(50),
	})

	type ResetPasswordFormValues = z.infer<typeof formSchema>

	const form = useForm<ResetPasswordFormValues>({
		resolver: zodResolver(formSchema),
		defaultValues: { email: "" },
		mode: "onBlur",
	})

	const [inProgress, setInProgress] = useState(false)
	const [showError, setShowError] = useState(null)
	const [showSuccess, setShowSuccess] = useState('')

	const _onSubmit: SubmitHandler<any> = (data) => {
		setInProgress(true)
		setShowError(null)
		setShowSuccess('')

		onSubmit(data.email)
			.then(successResponse => setShowSuccess(successResponse.message))
			.finally(() => setInProgress(false))
			.catch(errorResponse => setShowError(errorResponse))
	}

	const emailAddressPlaceholder = intl.formatMessage({ id: "app.passwordreset.email", defaultMessage: "Email Address" })

	return (
		<Form {...form}>
			<form onSubmit={form.handleSubmit(_onSubmit)}>
				<FormField
					disabled={inProgress}
					control={form.control}
					name="email"
					render={({ field }) => (
						<FormItem>
							<FormControl>
								<Input required className={showError ? "bg-rose-200 border-rose-400" : ""} type="email" placeholder={emailAddressPlaceholder} {...field} />
							</FormControl>
							<FormMessage />
						</FormItem>
					)}
				/>

				{showSuccess && <p className="mx-2 mt-8 text-sm text-green-700">{showSuccess}</p>}
				{showError && <p className="mx-2 mt-8 text-sm text-red-700"><ResetPasswordErrorMessage errorCode={showError} /></p>}

				<Button className="mt-14 w-full uppercase" type="submit" disabled={inProgress}>
					{inProgress && <SpinnerIcon className="mr-2 h-4 w-4 animate-spin" />}
					<FormattedMessage id="app.passwordreset.reset" defaultMessage="Reset Credentials" />
				</Button>
			</form>
		</Form>
	)
}

function ResetPasswordErrorMessage({ errorCode }: { errorCode: RestApiResponseCode }) {
	switch (errorCode) {
		case RestApiResponseCode.ErrorEmailNotFound:
			return (<ErrorMessageWithSupportLink id="app.passwordreset.error.notfound" defaultMessage="Your account was not found. Please try a different email address or contact member support {supportLink}." />)

		case RestApiResponseCode.ErrorMultipleAccountsEmail:
			return (<ErrorMessageWithSupportLink id="app.passwordreset.error.multiple" defaultMessage="Your email is associated with multiple accounts. Please contact member support {supportLink}." />)
	}

	return (<ErrorMessageWithSupportLink id="app.error.generic" defaultMessage="The requested operation failed. Please try again or contact member support {supportLink}." />)
}