import { FormattedMessage } from "react-intl";
import { ChildMember, Member } from "../../model/Members";
import { Control, UseFormReturn } from "react-hook-form";
import { fr, enUS } from 'date-fns/locale';
import { FormField, FormMessage } from "../../components/ui/form";
import { RadioGroup, RadioGroupItem } from "../../components/ui/radio-group";
import { Label } from "../../components/ui/label";

import { BirthDateField } from "./BirthDateField";
import { EmailField } from "./EmailField";
import { MemberHeader } from "./MemberHeader";

export interface ChildMemberFormProps {
	member: ChildMember
	guardians: Member[]
	form: UseFormReturn
	disabled: boolean
}

export enum ChildAppAccessType {
	UNKNOWN, EMAIL, GUARDIAN
}

export function ChildMemberForm({ member, form, guardians = [], disabled }: ChildMemberFormProps) {

	const locale = navigator.language.startsWith("fr") ? fr : enUS

	const appAccessType = form.watch(`${member.id}.appAccessType`)

	return (
		<div className="mt-10">
			<MemberHeader name={member.name} type={member.type} />

			<BirthDateField className="mt-4 w-full md:w-80" control={form.control} member={member} locale={locale} disabled={disabled} />

			<p className="mt-10 mx-2 md:mx-0">
				<FormattedMessage id="app.home.member.child.access.question" defaultMessage="Do you want this child to access the app?" />
			</p>

			<FormField
				disabled={disabled}
				control={form.control}
				name={`${member.id}.appAccessType`}
				render={({ field }) => (
					<div className="md:w-2/3">
						<RadioGroup className="mt-4 md:flex"
							disabled={disabled}
							value={field.value}
							onValueChange={value => {
								const option = parseInt(value, 10)
								field.onChange(option)

								if (option === ChildAppAccessType.GUARDIAN) {
									form.clearErrors(`${member.id}.email`)
								} else {
									form.clearErrors(`${member.id}.guardianId`)
								}
							}}>

							<div className="bg-stone-50 rounded-md border p-4 md:w-full flex flex-col justify-between">
								<div className="flex items-start space-x-2">
									<RadioGroupItem value={`${ChildAppAccessType.EMAIL}`} id={`${member.id}-yes`} checked={appAccessType === ChildAppAccessType.EMAIL} />
									<Label htmlFor={`${member.id}-yes`}><FormattedMessage id="app.home.member.child.yes" defaultMessage="<b>Yes</b>, I want my child to access the app and receive communications at the below email address." /></Label>
								</div>

								<EmailField className="ml-6 mt-6 w-auto md:w-80" member={member} required={appAccessType === ChildAppAccessType.EMAIL} disabled={appAccessType !== ChildAppAccessType.EMAIL} control={form.control} />
							</div>

							<div className="bg-stone-50 rounded-md border p-4 md:w-full flex flex-col justify-between">
								<div className="flex items-start space-x-2 md:min-w-80">
									<RadioGroupItem value={`${ChildAppAccessType.GUARDIAN}`} id={`${member.id}-no`} checked={appAccessType === ChildAppAccessType.GUARDIAN} />
									<Label htmlFor={`${member.id}-no`}><FormattedMessage id="app.home.member.child.no" defaultMessage="<b>No</b>, I do not want my child to have access to the app or receive emails. Going forward, I'd like emails related to this child (for example, registration confirmations) to be forwarded to the guardian selected below." /></Label>
								</div>

								<GuardianSelector child={member as ChildMember} guardians={guardians} control={form.control} disabled={appAccessType !== ChildAppAccessType.GUARDIAN} />
							</div>

						</RadioGroup>
						<FormMessage />
					</div>
				)}
			/>
		</div>
	)
}

function GuardianSelector({ child, guardians, control, disabled }: { child: ChildMember, guardians: Member[], control: Control, disabled: boolean }) {
	return (
		<FormField
			control={control}
			disabled={disabled}
			name={`${child.id}.guardianId`}
			render={({ field }) => (
				<RadioGroup className="mt-4 p-4"
					disabled={disabled}
					onBlur={field.onBlur}
					onValueChange={value => {
						const id = parseInt(value, 10)
						field.onChange(id)
					}}
					defaultValue={field.value}>
					{guardians.map(guardian =>
						<div key={guardian.id} className="flex items-center space-x-2">
							<RadioGroupItem value={guardian.id.toString()} id={`${child.id}-${guardian.id}`} checked={field.value === guardian.id} />
							<Label htmlFor={`${child.id}-${guardian.id}`}>{guardian.name}</Label>
						</div>
					)}
					<FormMessage />
				</RadioGroup>
			)}
		/>)
}
