import { FormattedMessage } from "react-intl";
import { Member } from "../../model/Members";
import { Control } from "react-hook-form";
import { Locale, format, isValid, parse } from "date-fns"
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "../../components/ui/form";
import { Popover, PopoverContent, PopoverTrigger } from "../../components/ui/popover";
import { CalendarIcon } from "@radix-ui/react-icons";
import { Calendar } from "../../components/ui/calendar";
import { Input } from "../../components/ui/input";
import { ChangeEventHandler, useState } from "react";
import { PopoverAnchor } from "@radix-ui/react-popover";

export interface BirthDateFieldProps {
	className?: string | undefined
	control: Control
	member: Member
	locale: Locale
	disabled?: boolean
}

export function BirthDateField({ className = "", control, member, locale, disabled = false }: BirthDateFieldProps) {

	const [inputValue, setInputValue] = useState(format(member.birthDate, "P", { locale }))

	return (<FormField
		disabled={disabled}
		control={control}
		name={`${member.id}.birthDate`}
		render={({ field }) => {

			const handleInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
				const newValue = e.currentTarget.value
				setInputValue(newValue)
				const date = parse(newValue, "P", new Date(), { locale });
				if (isValid(date)) {
					field.onChange(date)
				} else {
					field.onChange(null)
				}
			};

			return (
				<FormItem className={`flex flex-col mt-4 ${className}`}>
					<FormLabel className="mx-2 md:mx-0 text-base text-stone-500"><FormattedMessage id="app.home.member.birthdate" defaultMessage="*Birthdate" /></FormLabel>
					<FormControl>
						<Popover>
							<PopoverAnchor>
								<div className="relative">
									<Input
										className="md:border-x-0 md:border-t-0 md:shadow-none md:rounded-none md:focus-visible:rounded-md"
										value=
										{
											inputValue
										}
										onChange={handleInputChange}
									/>

									<div className="absolute inset-y-0 right-0 flex cursor-pointer items-center pr-3 text-gray-400">
										<PopoverTrigger asChild>
											<CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
										</PopoverTrigger>
										<PopoverContent className="w-auto p-0" align="start">
											<Calendar
												locale={locale}
												captionLayout="dropdown-buttons"
												fromYear={1900}
												toYear={new Date().getFullYear()}
												mode="single"
												defaultMonth={field.value}
												selected={field.value}
												onSelect={date => {
													if (date) {
														setInputValue(format(date, "P", { locale }))
													} else {
														setInputValue("")
													}
													field.onChange(date)
												}}
												disabled={(date) => date > new Date() || date < new Date("1900-01-01")}
												initialFocus={true} />
										</PopoverContent>
									</div>
								</div>
							</PopoverAnchor>
						</Popover>
					</FormControl>
					<FormMessage />
				</FormItem>
			)
		}} />)
}