import { FormattedMessage, useIntl } from "react-intl"
import { Member, Members, PrimaryMember } from "../../model/Members"
import { useEffect, useState } from "react"
import { MemberService } from "../../services/MemberService"
import { ChildAppAccessType, ChildMemberForm } from "./ChildMemberForm"
import { useForm } from "react-hook-form"
import { Form } from "../../components/ui/form"
import { Button } from "../../components/ui/button"
import { AuthenticationService } from "../../services/AuthenticationService"
import { guest } from "../../model/User"
import { zodResolver } from "@hookform/resolvers/zod"
import { ZodIssueCode, z } from "zod"
import { PrimaryMemberForm } from "./PrimaryMemberForm"
import { SecondaryMemberForm } from "./SecondaryMemberForm"
import { MidtownLogo } from "../../components/MidtownLogo"
import { WarningIcon } from "../../components/WarningIcon"
import { useNavigate } from "react-router-dom"
import pMemoize from "p-memoize"
import { useAuth } from "../../lib/authHook"
import { RestApiResponseCode } from "../../model/RestApiResponseCode"
import { useToast } from "../../components/ui/use-toast"
import { SpinnerIcon } from "../../components/SpinnerIcon"

export default function HomeScreen() {

	const [isLoading, setIsLoading] = useState(true)
	const [loadingError, setLoadingError] = useState(false)

	const [members, setMembers] = useState<Members>()

	const auth = useAuth()
	const navigate = useNavigate()

	useEffect(() => {
		const user = auth.user
		if (user === guest) return

		setLoadingError(false)

		MemberService.loadMembers(user.id, user.token)
			.then(members => setMembers(members))
			.finally(() => setIsLoading(false))
			.catch((errorResponse: RestApiResponseCode) => {
				if (errorResponse === RestApiResponseCode.ErrorFormAlreadySubmitted) {
					navigate("/submitted")
				} else {
					setLoadingError(true)
				}
			})
	}, [auth.user, navigate])

	return (<div className="p-6 flex flex-col items-center w-full h-full min-h-screen md:bg-gray-200">

		<div className="my-6 md:px-36 md:w-4/5 flex flex-col items-center md:items-start">
			<MidtownLogo className="mt-0 md:mb-6" />
		</div>

		<div className="py-14 md:p-12 w-full md:w-4/5 relative md:bg-white md:rounded-3xl md:mb-10">

			<div className="invisible sticky top-0 md:visible"><p className="text-stone-500 absolute right-0"><FormattedMessage id="app.home.requiredfields" defaultMessage="*Required Field" /></p></div>

			<div className="md:px-24">

				{isLoading &&
					<div className="my-40 flex items-center justify-center"><SpinnerIcon className="mr-2 h-4 w-4 animate-spin" /><p className="text-center"><FormattedMessage id="app.home.loading" defaultMessage="Loading..." /></p></div>
				}

				{loadingError &&
					<div className="my-40 flex items-center justify-center"><p className="text-center"><FormattedMessage id="app.home.loading.error" defaultMessage="The requested operation failed. Please try again later." /></p></div>
				}

				{(!isLoading && !loadingError) && <>

					<h2 className="font-medium text-2xl"><FormattedMessage id="app.home.header" defaultMessage="Update Your Account Information for the New Midtown Mobile App" /></h2>

					<p className="mt-2"><FormattedMessage id="app.home.header.instructions" defaultMessage="Verify or update your account information to ensure seamless access to the new Midtown Mobile App." /></p>

					<h1 className="font-medium text-2xl mt-20"><FormattedMessage id="app.home.member.header" defaultMessage="Adult Member Details" /></h1>

					<span className="mt-2 flex gap-2 items-center"><WarningIcon /><FormattedMessage id="app.home.member.instructions" defaultMessage="Each member needs a unique email address to access the new Midtown App." /></span>
				</>}

				{members && <MembersContent members={members} />}

			</div>
		</div>
	</div>)
}

const memoizedValidateUsername = pMemoize(MemberService.validateUsername, { cacheKey: ([memberId, username]) => `${memberId}-${username}` })

function validateUsername(member: PrimaryMember): (username: string) => Promise<boolean> {
	return (username: string) => {

		const user = AuthenticationService.user
		if (user === guest) return Promise.resolve(false)

		return memoizedValidateUsername(member.id, username, user.token).then((isValid) => {
			return isValid
		})
	}
}


const memoizedValidateEmail = pMemoize(MemberService.validateEmail, { cacheKey: ([memberId, email]) => `${memberId}-${email}` })

function validateEmail(member: Member): (email: string) => Promise<boolean> {
	return (email: string) => {

		const user = AuthenticationService.user
		if (user === guest) return Promise.resolve(false)

		return memoizedValidateEmail(member.id, email, user.token).then((isValid) => {
			return isValid
		})
	}
}


function MembersContent({ members }: { members: Members }) {

	const [inProgress, setInProgress] = useState(false)
	const { toast } = useToast()

	const intl = useIntl()

	const invalidEmailMessage = intl.formatMessage({ id: "app.home.member.error.email.invalid", defaultMessage: "Please enter a valid email address." })
	const emailAlreadyTakenMessage = intl.formatMessage({ id: "app.home.member.error.email.taken", defaultMessage: "Email has already been taken. Please enter a different one." })
	const emailIsTooLongMessage = intl.formatMessage({ id: "app.home.member.error.email.long", defaultMessage: "Email is too long. Please enter an email address with at most 50 characters." })
	const invalidBirthdateMessage = intl.formatMessage({ id: "app.home.member.error.birthdate.invalid", defaultMessage: "Please enter valid birthdate using format mm/dd/yyyy." })
	const minBirthdateMessage = intl.formatMessage({ id: "app.home.member.error.birthdate.min", defaultMessage: "Please select a more recent birthdate." })
	const maxBirthdateMessage = intl.formatMessage({ id: "app.home.member.error.birthdate.max", defaultMessage: "Please select an older birthdate." })
	const invalidUsernameMessage = intl.formatMessage({ id: "app.home.member.error.username.invalid", defaultMessage: "Please enter a valid username." })
	const usernameAlreadyTakenMessage = intl.formatMessage({ id: "app.home.member.error.username.taken", defaultMessage: "Username has already been taken. Please enter a different one." })
	const usernameIsTooLongMessage = intl.formatMessage({ id: "app.home.member.error.username.long", defaultMessage: "Username is too long. Please enter an username with at most 100 characters." })
	const passswordRequirementsMessage = intl.formatMessage({ id: "app.home.member.password.requirements", defaultMessage: "Password must be between 6-12 characters." });
	const invalidCurrentPasswordMessage = intl.formatMessage({ id: "app.home.member.error.currentpassword.invalid", defaultMessage: "Your current password is not valid. Please check." });
	const errorToastTitle = intl.formatMessage({ id: "app.home.submit.error.title", defaultMessage: "Error" })
	const errorToastMessage = intl.formatMessage({ id: "app.home.submit.error.message", defaultMessage: "Could not submit, check form for errors" })
	const childAppAccessTypeNotSelected = intl.formatMessage({ id: "app.home.member.error.appaccesstype.required", defaultMessage: "Please select one of the options above." })
	const invalidPhoneNumberMessage = intl.formatMessage({ id: "app.home.member.error.phoneNumber.invalid", defaultMessage: "Mobile phone number is not valid." })

	const baseMemberSchemaObject = {
		email: z.string()
			.email({ message: invalidEmailMessage })
			.max(50, { message: emailIsTooLongMessage }),

		birthDate:
			z.date({ required_error: invalidBirthdateMessage, invalid_type_error: invalidBirthdateMessage })
				.min(new Date("1900-01-01"), { message: minBirthdateMessage })
				.max(new Date(), { message: maxBirthdateMessage }),
	}

	const primaryMemberSchemaObject = {
		...baseMemberSchemaObject,
		phoneNumber: z.string().min(10, { message: invalidPhoneNumberMessage }),
		username: z.string().max(100, { message: usernameIsTooLongMessage }),
		currentPassword: z.string().optional().transform(e => e === "" ? undefined : e),
		password: z.string().min(6, { message: passswordRequirementsMessage }).max(12, { message: passswordRequirementsMessage }).optional().or(z.literal('')).transform(e => e === "" ? undefined : e),
		passwordConfirmation: z.string().optional().or(z.literal('')).transform(e => e === "" ? undefined : e),
	}

	const childMemberSchemaObject = {
		...baseMemberSchemaObject,
		guardianId: z.number().optional(),
		appAccessType: z.number({ required_error: childAppAccessTypeNotSelected }),
	}

	const childMembersSchema = members.children.reduce<any>((result, current) => {
		const schemaObject = { ...childMemberSchemaObject, email: childMemberSchemaObject.email.refine(validateEmail(current), { message: emailAlreadyTakenMessage }).optional() }
		const newItem = {
			[`${current.id}`]: z.object(schemaObject)
				.refine(
					({ appAccessType, guardianId }) => {
						if (appAccessType === ChildAppAccessType.EMAIL) {
							return true
						}

						return appAccessType === ChildAppAccessType.GUARDIAN && (guardianId && guardianId !== -1)
					},
					{ message: intl.formatMessage({ id: "app.home.member.error.guardian.required", defaultMessage: "Please select a guardian." }), path: ["guardianId"] }
				)
		}
		return { ...result, ...newItem }
	}, {})

	const secondaryMembersSchema = members.secondaries.reduce<any>((result, current) => {
		const schemaObject = { ...baseMemberSchemaObject, phoneNumber: z.string().min(10, { message: invalidPhoneNumberMessage }), email: baseMemberSchemaObject.email.refine(validateEmail(current), { message: emailAlreadyTakenMessage }) }
		const newItem = { [`${current.id}`]: z.object(schemaObject) }
		return { ...result, ...newItem }
	}, {})

	const schemaObject = {
		[`${members.primary.id}`]: z.object(
			{
				...primaryMemberSchemaObject,
				email: primaryMemberSchemaObject.email.refine(validateEmail(members.primary), { message: emailAlreadyTakenMessage }),
				username: primaryMemberSchemaObject.username
					.refine((value) => value && value !== "", { message: invalidUsernameMessage })
					.refine(validateUsername(members.primary), { message: usernameAlreadyTakenMessage }),
			}
		)

			.superRefine(({ currentPassword, password, passwordConfirmation }, context: z.RefinementCtx) => {
				if (!currentPassword && !password && !passwordConfirmation) return z.OK

				if (!currentPassword && (password !== "" || passwordConfirmation !== "")) {
					context.addIssue({
						code: ZodIssueCode.custom,
						message: intl.formatMessage({ id: "app.home.member.error.currentpassword.missing", defaultMessage: "Please enter your current password" }),
						fatal: true,
						path: ["currentPassword"],
					});
				}

				if (password !== "" && password !== passwordConfirmation) {
					context.addIssue({
						code: ZodIssueCode.custom,
						message: intl.formatMessage({ id: "app.home.member.error.password.donotmatch", defaultMessage: "Passwords don't match" }),
						fatal: true,
						path: ["passwordConfirmation"],
					});
				}
			}),
		...secondaryMembersSchema,
		...childMembersSchema,
	}

	const formSchema = z.object(schemaObject).superRefine((data: { [x: string]: any }, context: z.RefinementCtx) => {
		const entries = Object.entries(data)
		entries.forEach((entry) => {
			if (!entry[1].email || (entry[1].appAccessType && entry[1].appAccessType !== ChildAppAccessType.EMAIL)) {
				return;
			}

			const others = entries.filter(e => e !== entry)
			const duplicate = others.find(e => e[1].email === entry[1].email)

			if (duplicate) {
				context.addIssue({
					code: ZodIssueCode.custom,
					message: emailAlreadyTakenMessage,
					fatal: true,
					path: [`${entry[0]}.email`]
				});

				return;
			}
		})

		return z.NEVER;
	})

	type MembersFormValues = z.infer<typeof formSchema>

	const form = useForm<MembersFormValues>({
		resolver: zodResolver(formSchema),
		defaultValues: _membersToFormValues(members),
		mode: "onBlur",
	})

	function _membersToFormValues(members: Members): MembersFormValues {
		const otherMembers = [...members.secondaries, ...members.children]

		const otherMembersFormValues = otherMembers.reduce<any>((result, current) => {
			const newItem = { [`${current.id}`]: { ...current } }

			if (current.type === 'child') {
				if (current.email) {
					(newItem[`${current.id}`] as any).appAccessType = ChildAppAccessType.EMAIL
				} else if (!current.email && current.guardianId !== -1) {
					(newItem[`${current.id}`] as any).appAccessType = ChildAppAccessType.GUARDIAN
				}
			}

			return { ...result, ...newItem }
		}, {})

		const { password, ...primaryData } = members.primary

		return {
			[members.primary.id]: { ...primaryData },
			...otherMembersFormValues
		}
	}

	function _formValuesToMembers(data: MembersFormValues): Members {
		const primaryMemberData = data[members.primary.id]
		const primaryMember = {
			...members.primary,
			...primaryMemberData,
		}

		const secondaries = members.secondaries.map(s => {
			return {
				...s,
				...data[s.id],
			}
		})

		const children = members.children.map(s => {
			const { appAccessType, ...props } = data[s.id]
			return {
				...s,
				...props,
			}
		})

		return new Members(primaryMember, secondaries, children)
	}

	const navigate = useNavigate()
	const auth = useAuth()

	const _onSubmit = (data: MembersFormValues) => {
		const user = AuthenticationService.user
		if (user === guest) return

		setInProgress(true)

		const members = _formValuesToMembers(data)
		MemberService.updateMembers(members, user.token)
			.then(() => {
				auth.signout()
				navigate("/success")
			})
			.catch(resposeCode => {
				if (resposeCode === RestApiResponseCode.ErrorIncorrectLogin) {
					form.setError(`${members.primary.id}.currentPassword`, { message: invalidCurrentPasswordMessage }, { shouldFocus: true })
				}

				toast({ variant: "error", title: errorToastTitle, description: errorToastMessage })
			})
			.finally(() => setInProgress(false))
	}

	return (<Form {...form}>
		<form onSubmit={form.handleSubmit(_onSubmit)}>
			<div className="flex flex-col content-center">

				<PrimaryMemberForm member={members.primary} control={form.control} disabled={inProgress} />

				{members.secondaries.map(secondary => <SecondaryMemberForm key={secondary.id} member={secondary} control={form.control} disabled={inProgress} />)}

				{members.children.length > 0 && <div>

					<h1 className="font-medium text-2xl mt-20"><FormattedMessage id="app.home.children.header" defaultMessage="Child Member Details" /></h1>
					<span className="mt-2 flex gap-2 items-center md:w-2/3"><WarningIcon /><FormattedMessage id="app.home.children.instructions" defaultMessage="Verify your child's/children's information. If you want them to have access to the Midtown App, you must add a unique email address for each child." /></span>

					{members.children.map(child => <ChildMemberForm key={child.id} member={child} guardians={[members.primary, ...members.secondaries.filter(s => s.isEligibleGuardian)]} form={form} disabled={inProgress} />)}

				</div>}

				<span className="mt-10 flex gap-2 items-center"><WarningIcon /><FormattedMessage id="app.home.submit.instructions" defaultMessage="Please carefully review the above information before submitting. This form can only be submitted once." /></span>

				<Button className="my-10 uppercase self-center md:self-start px-8" type="submit" disabled={inProgress}>
					{inProgress && <SpinnerIcon className="mr-2 h-4 w-4 animate-spin" />}
					<FormattedMessage id="app.home.submit" defaultMessage="Submit" />
				</Button>
			</div>
		</form>
	</Form>)
}