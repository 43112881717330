import { getDeviceId } from "../lib/deviceId";
import User, { guest } from "../model/User";
import { ResetPasswordResponse } from "../model/ResetPasswordResponse";
import { baseUrl, defaultHeaders } from "./network";
import { RestApiResponseCode } from "../model/RestApiResponseCode";

const AuthenticationService = {

	get user(): User {
		return getUser() || guest
	},

	get isAuthenticated(): boolean { return this.user !== guest },

	async signin(username: string, password: string): Promise<User> {
		const body = {
			username,
			password,
			deviceId: getDeviceId()
		}

		try {
			const response = await fetch(`${baseUrl}/members/account/login`,
				{
					method: "post",
					headers: defaultHeaders,
					body: JSON.stringify(body)
				}
			)

			const responseBody = await response.json()
			if (!response.ok) {
				return Promise.reject(responseBody.errorResponse.responseCode)
			}

			const user = { id: responseBody.memberId, token: responseBody.memberAuthToken }
			saveUser(user)
			return user
		} catch (e) {
			saveUser(null)
			return Promise.reject(RestApiResponseCode.ErrorInternal)
		}
	},

	signout() {
		saveUser(null)
	},

	async resetPassword(email: string): Promise<ResetPasswordResponse> {
		const body = { email }

		try {
			const response = await fetch(`${baseUrl}/members/account/passwordreset`,
				{
					method: "post",
					headers: defaultHeaders,
					body: JSON.stringify(body)
				}
			)

			const responseBody = await response.json()
			if (!response.ok) {
				return Promise.reject(responseBody.errorResponse.responseCode)
			}
			return Promise.resolve({ message: responseBody.message })
		} catch (e) {
			return Promise.reject(RestApiResponseCode.ErrorInternal)
		}
	}
}

function saveUser(user: User | null) {
	if (user) {
		sessionStorage.setItem('user', JSON.stringify(user))
	} else {
		sessionStorage.removeItem('user')
	}
}

function getUser(): User | null {
	const saved = sessionStorage.getItem('user')
	if (saved) {
		try {
			return JSON.parse(saved)
		} catch (e) {
			sessionStorage.removeItem('user')
		}
	}

	return null
}


export { AuthenticationService }
