import { FormattedMessage, useIntl } from "react-intl";
import { MidtownLogo } from "../components/MidtownLogo";
import { Separator } from "../components/ui/separator";

export default function AlreadySubmittedScreen() {
	const intl = useIntl()

	const appPromoLink = intl.formatMessage({ id: "app.success.promo.link.url", defaultMessage: "https://www.midtown.com/member-app/" })

	return (<>
		<MidtownLogo />
		<div className='p-6 flex flex-col items-center'>
			<h1 className="mt-10 font-medium text-2xl"><FormattedMessage id="app.submitted.header" defaultMessage="You've Updated Your Account" /></h1>

			<div className="md:w-1/3 flex flex-col items-center">

				<Separator className="mt-6 w-2/3" />

				<p className="mt-6 mx-4 text-sm text-stone-500 text-center">
					<FormattedMessage id="app.submitted.message" defaultMessage="It looks like you've already updated your information and are entered to win weekly raffles until the app releases this fall. For any questions or future updates to this account, please contact member services by filling out {supportForm} or calling your club's main phone number." values={{
						supportForm: (<a className="underline text-sky-500 text-nowrap" href="https://www.midtown.com/contact-us/"><FormattedMessage id="app.success.message.link.text" defaultMessage="this form" /></a>),
					}} />
				</p>

				<p className="mt-6 mx-8 text-sm text-stone-500 text-center">
					<FormattedMessage id="app.success.promo.message" defaultMessage="Want to see weekly previews of what's to come in your new member app? {appLink}" values={{
						appLink: (<a className="underline text-sky-500 text-nowrap" href={appPromoLink} ><FormattedMessage id="app.success.promo.link.text" defaultMessage="click here" /></a>)
					}} />
				</p>
			</div>
		</div>
	</>)
}