import { parseISO } from "date-fns"
import { Members } from "../model/Members"
import { baseUrl, defaultHeaders } from "./network"

const MemberService = {

	async loadMembers(memberId: number, token: string): Promise<Members> {
		const response = await fetch(`${baseUrl}/members/${memberId}`, {
			headers: {
				...defaultHeaders,
				'x-member-token': token,
			}
		})

		const responseBody = await response.json()
		if (!response.ok) {
			return Promise.reject(responseBody.errorResponse.responseCode)
		}

		function _parseDate(member: any): any {
			return {
				...member,
				birthDate: parseISO(member.birthDate)
			}
		}

		return new Members(_parseDate(responseBody.primary), responseBody.secondaries.map(_parseDate), responseBody.children.map(_parseDate))
	},

	async updateMembers(members: Members, token: string): Promise<Members> {
		const response = await fetch(`${baseUrl}/members/${members.primary.id}`, {
			method: "PUT",
			headers: {
				...defaultHeaders,
				'x-member-token': token,
			},
			body: JSON.stringify(members)
		})

		const responseBody = await response.json()
		if (!response.ok) {
			return Promise.reject(responseBody.errorResponse.responseCode)
		}

		return responseBody
	},

	async validateEmail(memberId: number, email: string, token: string): Promise<boolean> {
		try {
			const response = await fetch(`${baseUrl}/members/${memberId}/validation`, {
				method: "POST",
				headers: {
					...defaultHeaders,
					'x-member-token': token,
				},
				body: JSON.stringify({ email })
			})

			const responseBody = await response.json()
			if (!response.ok) {
				return Promise.resolve(false)
			}

			return Promise.resolve(responseBody.isValid)
		} catch (error) {
			return Promise.resolve(true)
		}
	},

	async validateUsername(memberId: number, username: string, token: string): Promise<boolean> {
		try {
			const response = await fetch(`${baseUrl}/members/${memberId}/validation`, {
				method: "POST",
				headers: {
					...defaultHeaders,
					'x-member-token': token,
				},
				body: JSON.stringify({ username })
			})

			const responseBody = await response.json()
			if (!response.ok) {
				return Promise.resolve(false)
			}

			return Promise.resolve(responseBody.isValid)
		} catch (error) {
			return Promise.resolve(true)
		}
	}

}

export { MemberService }
