import { FormattedMessage } from "react-intl"
import { MemberType } from "../../model/Members"

export interface MemberHeaderProps {
	name: string
	type: MemberType
}

export function MemberHeader({ name, type }: MemberHeaderProps) {
	return (
		<div className="flex justify-between">
			<div>
				<p className="text-lg font-semibold">{name}</p>
				<MemberTypeText type={type} />
			</div>
			<p className="text-stone-500 text-sm md:invisible"><FormattedMessage id="app.home.requiredfields" defaultMessage="*Required Field" /></p>
		</div>
	)
}


function MemberTypeText({ type }: { type: MemberType }) {
	switch (type) {
		case "primary":
			return <p className="text-stone-500"><FormattedMessage id="app.home.member.type.primary" defaultMessage="Main Account Holder" /></p>
		case "secondary":
			return <p className="text-stone-500"><FormattedMessage id="app.home.member.type.secondary" defaultMessage="Adult" /></p>
		default:
			return <p className="text-stone-500"><FormattedMessage id="app.home.member.type.child" defaultMessage="Child" /></p>
	}
}