import { ResetPasswordForm } from "./ResetPasswordForm";
import { AuthenticationService } from "../../services/AuthenticationService";
import { FormattedMessage } from "react-intl";
import { MidtownLogo } from "../../components/MidtownLogo";


export default function ResetPasswordScreen() {

	const onSubmit = async (email: string) => {
		return await AuthenticationService.resetPassword(email)
	}

	return (
		<>
			<MidtownLogo />
			<div className='p-6 flex flex-col items-center'>
				<div className="w-auto md:w-96">

					<p className="py-4 mx-2 text-xs text-stone-500">
						<FormattedMessage id="app.passwordreset.description" defaultMessage="Please enter the email address associated with your account. We will send you an email with new credentials." />
					</p>

					<ResetPasswordForm onSubmit={onSubmit} />

					<p className="py-4 mx-2 text-xs text-stone-500">
						<FormattedMessage id="app.passwordreset.instructions" defaultMessage="Don't see an email? Check your spam for an email address ending in @midtownclubs.com" />
					</p>

				</div>
			</div>
		</>
	)
}