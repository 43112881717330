import { SecondaryMember } from "../../model/Members";
import { Control } from "react-hook-form";
import { fr, enUS } from 'date-fns/locale';
import { BirthDateField } from "./BirthDateField";
import { EmailField } from "./EmailField";
import { MemberHeader } from "./MemberHeader";
import { PhoneNumberField } from "./PhoneNumberField";

export interface SecondaryMemberFormProps {
	member: SecondaryMember
	control: Control
	disabled: boolean
}

export function SecondaryMemberForm({ member, control, disabled }: SecondaryMemberFormProps) {

	const locale = navigator.language.startsWith("fr") ? fr : enUS

	return (
		<div className="mt-10">
			<MemberHeader name={member.name} type={member.type} />

			<div className="md:flex md:gap-4">
				<BirthDateField className="mt-4 w-full md:w-80" control={control} member={member} locale={locale} disabled={disabled} />

				<EmailField control={control} member={member} required={true} disabled={disabled} />

				<PhoneNumberField member={member} required={true} control={control} disabled={disabled} />
			</div>
		</div>
	)
}


