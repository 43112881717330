import { LoginForm } from "./LoginForm";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../lib/authHook";
import { FormattedMessage } from "react-intl";
import { useState } from "react";
import { RestApiResponseCode } from "../../model/RestApiResponseCode";
import { MidtownLogo } from "../../components/MidtownLogo";

export default function LoginScreen() {

	const navigate = useNavigate()
	const location = useLocation()
	const auth = useAuth()

	const from = location.state?.from?.pathname || "/"

	const [inProgress, setInProgress] = useState(false)
	const [showError, setShowError] = useState<RestApiResponseCode | null>(null)

	const onSubmit = (username: string, password: string) => {

		setInProgress(true)
		setShowError(null)

		auth.signin(username, password)
			.then(() => {
				// Send them back to the page they tried to visit when they were
				// redirected to the login page. Use { replace: true } so we don't create
				// another entry in the history stack for the login page.  This means that
				// when they get to the protected page and click the back button, they
				// won't end up back on the login page, which is also really nice for the
				// user experience.
				navigate(from, { replace: true })
			})
			.finally(() => setInProgress(false))
			.catch((errorResponse: RestApiResponseCode) => {
				if (errorResponse === RestApiResponseCode.ErrorFormAlreadySubmitted) {
					navigate("/submitted", { replace: true })
				} else {
					setShowError(errorResponse)
				}
			})
	}

	return (
		<>
			<MidtownLogo />
			<div className='p-6 flex flex-col items-center'>
				<div className="w-auto md:w-96">
					<p className="py-4 mx-2 text-xs text-stone-500">
						<FormattedMessage id="app.login.description" defaultMessage="Secure your access! Log in to confirm account info and be ready for the all-new Midtown Member App coming soon." />
					</p>
					<p className="mx-2 text-xs text-stone-500">
						<FormattedMessage id="app.login.notmain" defaultMessage="Not the main account holder? Forward this page so they can verify your account information." />
					</p>

					<LoginForm onSubmit={onSubmit} inProgress={inProgress} showError={showError} />
				</div>
			</div>
		</>
	)
}