import { useIntl } from "react-intl"
import stars from "./svg/stars.svg";

export function StarsImage(props: { className?: string }) {

	const intl = useIntl()

	const warningIconAlt = intl.formatMessage({ id: "app.alt.stars.image", defaultMessage: "Stars Image" })

	return <img src={stars} alt={warningIconAlt} {...props} />
}