import { SubmitHandler, useForm } from "react-hook-form"

import { Button } from "../../components/ui/button"
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
} from "../../components/ui/form"
import { Input, PasswordInput } from "../../components/ui/input"
import { Link } from "react-router-dom"
import { FormattedMessage } from "react-intl"
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { RestApiResponseCode } from "../../model/RestApiResponseCode"
import { ErrorMessageWithSupportLink } from "../../components/ErrorMessageWithSupportLink"
import { SpinnerIcon } from "../../components/SpinnerIcon"

const formSchema = z.object({
	username: z.string(),
	password: z.string().max(100),
})

type LoginFormValues = z.infer<typeof formSchema>

export interface LoginFormProps {
	onSubmit: (email: string, password: string) => void
	inProgress: boolean
	showError: RestApiResponseCode | null
}

export function LoginForm({ onSubmit, inProgress, showError }: LoginFormProps) {

	const form = useForm<LoginFormValues>({
		resolver: zodResolver(formSchema),
		defaultValues: { username: "", password: "" },
		mode: "onBlur",
	})

	const _onSubmit: SubmitHandler<LoginFormValues> = (data) => {
		onSubmit(data.username, data.password)
	}

	return (
		<Form {...form}>
			<form onSubmit={form.handleSubmit(_onSubmit)}>
				<FormField
					disabled={inProgress}
					control={form.control}
					name="username"
					render={({ field }) => (
						<FormItem className="mt-3">
							<FormLabel className="mx-2 text-base text-stone-500"><FormattedMessage id="app.login.username" defaultMessage="Username" /></FormLabel>
							<FormControl>
								<Input required className={showError ? "bg-rose-200 border-rose-400" : ""} {...field} />
							</FormControl>
						</FormItem>
					)}
				/>
				<FormField
					disabled={inProgress}
					control={form.control}
					name="password"
					render={({ field }) => (
						<FormItem className="mt-3">
							<FormLabel className="mx-2 text-base text-stone-500"><FormattedMessage id="app.login.password" defaultMessage="Password" /></FormLabel>
							<FormControl>
								<PasswordInput required autoComplete="current-password" className={showError ? "bg-rose-200 border-rose-400" : ""} {...field} />
							</FormControl>
						</FormItem>
					)}
				/>

				<p className="mx-2 mt-3 text-sm text-stone-500 underline text-center"><Link to="/password-reset"><FormattedMessage id="app.login.forgot" defaultMessage="Forgot Username / Password" /></Link></p>

				{showError && <p className="mx-2 mt-8 text-sm text-red-700">
					<LoginErrorMessage errorCode={showError} />
				</p>}

				<Button className="mt-14 w-full uppercase" type="submit" disabled={inProgress}>
					{inProgress && <SpinnerIcon className="mr-2 h-4 w-4 animate-spin" />}
					<FormattedMessage id="app.login.login" defaultMessage="Log in" />
				</Button>
			</form>
		</Form>
	)
}

function LoginErrorMessage({ errorCode }: { errorCode: RestApiResponseCode }) {
	switch (errorCode) {
		case RestApiResponseCode.ErrorIncorrectLogin:
			return (<ErrorMessageWithSupportLink id="app.login.error.invalid" defaultMessage="The username or password you entered are incorrect. Please try again or contact member support {supportLink}." />)

		case RestApiResponseCode.ErrorMemberNotMain:
			return (<FormattedMessage id="app.login.error.notmain" defaultMessage="Credentials do not match the main account holder. Please enter main account holder's credentials to continue." />)

		case RestApiResponseCode.ErrorExceedLoginAttempt:
			return (<ErrorMessageWithSupportLink id="app.login.error.attempts" defaultMessage="You have exceeded the number of incorrect log in attempts, please try again in 10 minutes or contact member support {supportLink}" />)
	}

	return (<ErrorMessageWithSupportLink id="app.error.generic" defaultMessage="The requested operation failed. Please try again or contact member support {supportLink}." />)
}