export enum RestApiResponseCode {
	ErrorExceedLoginAttempt = "ErrorExceedLoginAttempt",
	ErrorIncorrectLogin = "ErrorIncorrectLogin",
	ErrorMemberNotMain = "ErrorMemberNotMain",
	ErrorEmailNotFound = "ErrorEmailNotFound",
	ErrorMultipleAccountsEmail = "ErrorMultipleAccountsEmail",
	ErrorMissingAuthToken = "ErrorMissingAuthToken",
	ErrorInternal = "ErrorInternal",
	ErrorMissingParams = "ErrorMissingParams",
	ErrorMemberNotFound = "ErrorMemberNotFound",
	ErrorCannotUpdatePassword = "ErrorCannotUpdatePassword",
	ErrorCannotSaveMember = "ErrorCannotSaveMember",
	ErrorFormAlreadySubmitted = "ErrorFormAlreadySubmitted",
	SuccessResetPassword = "SuccessResetPassword",
}

