import { FormattedMessage } from "react-intl";
import { Member } from "../../model/Members";
import { Control } from "react-hook-form";
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "../../components/ui/form";
import { Input } from "../../components/ui/input";
import { ChangeEventHandler, useState } from "react";

export interface PhoneNumberFieldProps {
	control: Control
	member: Member
	required: boolean
	disabled?: boolean
	className?: string
}

function format(phoneNumber: string): string {
	const cleaned = phoneNumber.replace(/\D/g, '');
	const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);

	if (match) {
		const groups = match.slice(1).filter(Boolean);
		return groups.join('-');
	}

	return phoneNumber;
}


export function PhoneNumberField({ control, member, required, disabled = false, className }: PhoneNumberFieldProps) {

	const [inputValue, setInputValue] = useState(format(member.phoneNumber))


	return (<FormField
		disabled={disabled}
		control={control}
		name={`${member.id}.phoneNumber`}
		render={({ field }) => {

			const handleInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
				const newValue = e.currentTarget.value
				setInputValue(format(newValue))
				field.onChange(newValue.replace(/\D/g, ''));
			}

			return (
				<FormItem className={`mt-4 w-full md:w-80 ${className}`}>
					<FormLabel className="mx-2 md:mx-0 text-base text-stone-500"><FormattedMessage id="app.home.member.phoneNumber" defaultMessage="*Mobile Phone Number" /></FormLabel>
					<FormControl>
						<Input
							required={required}
							disabled={disabled}
							{...field}
							type="tel"
							maxLength={12}
							value={inputValue}
							onChange={handleInputChange}
							className="md:border-x-0 md:border-t-0 md:shadow-none md:rounded-none md:focus-visible:rounded-md" />
					</FormControl>
					<FormMessage />
				</FormItem>
			)
		}}
	/>)
}