import { FormattedMessage } from "react-intl";
import { Member } from "../../model/Members";
import { Control } from "react-hook-form";
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "../../components/ui/form";
import { Input } from "../../components/ui/input";

export interface EmailFieldProps {
	control: Control
	member: Member
	required: boolean
	disabled?: boolean
	className?: string
}

export function EmailField({ control, member, required, disabled = false, className }: EmailFieldProps) {
	return (<FormField
		disabled={disabled}
		control={control}
		name={`${member.id}.email`}
		render={({ field }) => (
			<FormItem className={`mt-4 w-full md:w-80 ${className}`}>
				<FormLabel className="mx-2 md:mx-0 text-base text-stone-500"><FormattedMessage id="app.home.member.email" defaultMessage="*Email" /></FormLabel>
				<FormControl>
					<Input required={required} disabled={disabled} {...field} type="email" className="md:border-x-0 md:border-t-0 md:shadow-none md:rounded-none md:focus-visible:rounded-md" />
				</FormControl>
				<FormMessage />
			</FormItem>
		)}
	/>)
}