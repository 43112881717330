import * as React from "react";

import { AuthenticationService } from "../services/AuthenticationService";
import User, { guest } from "../model/User";

interface AuthContextType {
	user: User
	signin: (username: string, password: string) => Promise<void>
	signout: () => void
}

const AuthContext = React.createContext<AuthContextType>(null!);

export function AuthProvider({ children }: { children: React.ReactNode }) {
	const [user, setUser] = React.useState<User>(AuthenticationService.user)

	const signin = async (newUser: string, password: string): Promise<void> => {
		return AuthenticationService.signin(newUser, password).then(user => {
			setUser(user)
		})
	}

	const signout = () => {
		AuthenticationService.signout()
		setUser(guest)
	}

	const value = { user, signin, signout }

	return (<AuthContext.Provider value={value}>{children}</AuthContext.Provider>)
}

export function useAuth() {
	return React.useContext(AuthContext)
}