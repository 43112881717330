export type MemberType = "primary" | "secondary" | "child"

export interface Member {
	id: number
	name: string
	email: string
	birthDate: Date
	type: MemberType
	isEligibleGuardian: boolean
	phoneNumber: string
}

export interface PrimaryMember extends Member {
	type: "primary"
	username: string
	password: string
}

export interface SecondaryMember extends Member {
	type: "secondary"
}

export interface ChildMember extends Member {
	type: "child"
	guardianId: number | undefined
}

export class Members {
	primary: PrimaryMember
	secondaries: SecondaryMember[]
	children: ChildMember[]

	constructor(primary: PrimaryMember, secondaries: SecondaryMember[], children: ChildMember[]) {
		this.primary = primary
		this.secondaries = secondaries
		this.children = children
	}
}