import { useIntl } from "react-intl";
import warningIcon from "./svg/warning.svg";

export function WarningIcon(props: { className?: string }) {

	const intl = useIntl()

	const warningIconAlt = intl.formatMessage({ id: "app.alt.warning.icon", defaultMessage: "Attention Icon" })

	return <img className="w-[20px] h-[20px]" src={warningIcon} alt={warningIconAlt} {...props} />
}